exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../fonts/Flaticon.eot"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../fonts/Flaticon.eot") + "?#iefix");
var ___CSS_LOADER_URL___2___ = urlEscape(require("../fonts/Flaticon.woff"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../fonts/Flaticon.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../fonts/Flaticon.svg") + "#Flaticon");

// Module
exports.push([module.id, "\t/*\n  \tFlaticon icon font: Flaticon\n  \tCreation date: 05/11/2018 16:13\n  \t*/\n\n@font-face {\n  font-family: \"Flaticon\";\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n  src: url(" + ___CSS_LOADER_URL___1___ + ") format(\"embedded-opentype\"),\n       url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff\"),\n       url(" + ___CSS_LOADER_URL___3___ + ") format(\"truetype\"),\n       url(" + ___CSS_LOADER_URL___4___ + ") format(\"svg\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@media screen and (-webkit-min-device-pixel-ratio:0) {\n  @font-face {\n    font-family: \"Flaticon\";\n    src: url(" + ___CSS_LOADER_URL___4___ + ") format(\"svg\");\n  }\n}\n\n[class^=\"flaticon-\"]:before, [class*=\" flaticon-\"]:before,\n[class^=\"flaticon-\"]:after, [class*=\" flaticon-\"]:after {\n  font-family: Flaticon;\n        font-size: 60px;\n        font-style: normal;\n}\n\n\n\n.flaticon-wedding-ring:before { content: \"\\f100\"; }\n.flaticon-wedding-ring-1:before { content: \"\\f101\"; }\n.flaticon-wedding:before { content: \"\\f102\"; }\n.flaticon-bird:before { content: \"\\f103\"; }\n.flaticon-birds-in-love:before { content: \"\\f104\"; }\n.flaticon-like:before { content: \"\\f105\"; }\n.flaticon-chat:before { content: \"\\f106\"; }\n.flaticon-message:before { content: \"\\f107\"; }\n.flaticon-heart:before { content: \"\\f108\"; }\n.flaticon-heart-1:before { content: \"\\f109\"; }\n.flaticon-heart-2:before { content: \"\\f10a\"; }", ""]);


import React from 'react'
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import strory1 from '../../images/events/1.jpg'
import strory2 from '../../images/events/2.jpg'

import './style.css'

const Location = () => {
    return(

        
    <div id="event" className="service-area section-padding">
        <div className="container">
            <Sectiontitle section={'When & Where'}/>
            <div className="service-area-menu">
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <img src={strory1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3>Wedding Ceremony</h3>
                                <span><b>All will be invited to the wedding ceremony. </b></span>
                                <span>Saturday, 26 February 22, 1:30 PM - 3.00 PM</span>
                                <span>57 - 59 Eccles Public Road, East Bank Demerara, Guyana, South America.</span>
                                <p></p>
                                <a target="_blank" rel="noopener noreferrer"  href="https://goo.gl/maps/CvUacRP9eoyj3m8C7">See Location</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="ceromony-content ceromony-content2">
                            <h3>Reception Party</h3>
                            <span><b className="ceremonyred">It would be nice to have everyone at our reception; unfortunately, due to covid regulations we will not be able to accommodate everyone. Reception Invitations only.</b></span>
                            <p> </p>
                            {/*<Link to="/">See Location</Link>*/}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                            <img src={strory2} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Location;
